import { Typography } from "@mui/material";

export const TituloPagina = ({ texto, sx }) => {
  const asx = sx ? sx : { pt: 2 };
  return (
    <Typography
      component="h1"
      align="center"
      variant="h3"
      color="text.primary"
      sx={asx}
      gutterBottom
    >
      {texto}
    </Typography>
  );
};

export const SubTitulo = ({ texto }) => {
  return (
    <Typography
      component="h4"
      align="left"
      variant="h4"
      color="text.primary"
      sx={{ pt: 2 }}
      gutterBottom
    >
      {texto}
    </Typography>
  );
};

export const TextoGrande = ({ texto, align, sx }) => {
  const atxt = texto.map ? texto : [texto];
  return (
    <>
      {atxt.map((txt, i) => (
        <Typography
          key={i}
          sx={sx}
          variant="h5"
          align={align || "center"}
          color="text.secondary"
          paragraph
          gutterBottom
        >
          {txt}
        </Typography>
      ))}
    </>
  );
};

export const TextoParrafo = ({ texto, align, sx, asListItem }) => {
  const atxt = texto.length ? texto : [texto];
  const asx = sx || { fontSize: "18px" };
  return (
    <>
      {atxt.map((txt, i) =>
        asListItem ? (
          <li>
            <Typography
              key={i}
              variant="body1"
              align={align || "justify"}
              gutterBottom
              paragraph
              sx={asx}
            >
              {txt}
            </Typography>
          </li>
        ) : (
          <Typography
            key={i}
            variant="body1"
            align={align || "justify"}
            gutterBottom
            paragraph
            sx={asx}
          >
            {txt}
          </Typography>
        )
      )}
    </>
  );
};
