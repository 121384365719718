import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import Container from "@mui/material/Container";
import { TituloPagina } from "./textElements";
import { Grid } from "@mui/material";



const PaginaContacto = () => {
    const formik = useFormik({
        initialValues: {
            nombre: "",
            email: "",
            consulta: "",
        },
        //validationSchema: undefined,
        onSubmit: (values) => {
            //do something
        }
    });
    return (
        <Container>
            <TituloPagina texto="Contacto" />
            <form onSubmit={formik.handleSubmit}>
                <Grid container direction={{xs:"column",sm:"row"}} spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth margin={"dense"} id="nombre" name="nombre" label="nombre" value={formik.values.nombre} onChange={formik.handleChange} error={formik.touched.nombre && Boolean(formik.errors.nombre)} />
                    </Grid> 
                    <Grid item xs={12} sm={6}>
                        <TextField fullWidth margin={"dense"} id="email" name="email" label="email" value={formik.values.nombre} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth margin={"dense"} multiline maxRows={4} id="consulta" name="consulta" label="consulta" value={formik.values.nombre} onChange={formik.handleChange} error={formik.touched.consulta && Boolean(formik.errors.consulta)} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button color="primary" type="submit" variant="contained">Enviar</Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    )
}

export default PaginaContacto;