import React from "react";
import { Container, Grid, Paper } from "@mui/material";
import {
  SubTitulo,
  TextoGrande,
  TextoParrafo,
  TituloPagina,
} from "./textElements";
import { VideoIncrustado } from "./VideoIncrustado";
import { useGetData } from "./getdata";
import {
  useAnimElementos,
  ElementoAnimado,
  ElementoAnimadoSingle,
} from "./ElementoAnimado";

const ElementoVideo = ({ video }) => (
  <Grid container spacing={3} direction={{ xs: "column", sm: "row" }}>
    <Grid item xs={12}>
      <TextoGrande sx={{ mt: 4, mb: 2 }} texto={video.titulo} align="left" />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Paper elevation={8}>
        <VideoIncrustado videoid={video.videoid} />
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextoParrafo texto={video.texto} />
    </Grid>
  </Grid>
);

const PaginaSalud = () => {
  const [videos] = useGetData("salud");
  const [animPhase, callbackEndElementAnim] = useAnimElementos();

  return (
    <Container>
      <ElementoAnimadoSingle elementData={videos}>
        <TituloPagina texto={videos?.titulo || ""} />
        <TextoParrafo texto={videos?.texto || []} />
        <SubTitulo texto={videos?.subtitulo || ""} />
      </ElementoAnimadoSingle>
      {!videos && <span>CARGANDO</span>}
      {videos?.videos &&
        videos.videos.map((video, i) => (
          <ElementoAnimado
            key={i}
            iElement={i}
            elementData={video}
            animPhase={animPhase}
            onEndAnim={callbackEndElementAnim}
          >
            <ElementoVideo video={video} />
          </ElementoAnimado>
        ))}
    </Container>
  );
};

export default PaginaSalud;
