import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import logoChico from "../assets/img/icono_emeiene.svg";
import logoGrande from "../assets/img/icono_emeiene_grande.svg";
import { NavLink } from 'react-router-dom';
import { useTheme } from "@mui/system";
import "../assets/css/defaults.css";

const pages = [
  {titulo: "Inicio", link:"Inicio"},
  {titulo: "Salud", link:"Salud"},
  {titulo: "Creación", link:"Creacion"},
  {titulo: "Juegos", link:"Juegos"},
  {titulo: "Videos", link:"Videos"},
  //{titulo: "Servicios", link:"Servicios"},
  //{titulo: "Contacto", link:"Contacto"},
];

const LIMITE = "md";



const ResponsiveAppBar = ({ setPagina }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const theme = useTheme();

  const LinkStyle = {
    marginLeft: theme.spacing(1),
    textDecoration: "none",
    display: "block",
    fontSize: 24,
    fontWeight: "bold",
    padding: "4px 12px",
    borderRadius: "24px",
    color: theme.palette.primary.dark,
    whiteSpace: "nowrap",
   };

const LinkStyleActive = {
    ...LinkStyle,
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
   };

const getNavLinkStyle = ({isActive}) => {
  return isActive ? LinkStyleActive : LinkStyle;
}

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenu = (item) => {
    setAnchorElNav(null);
    setPagina(item);
  };

  return (
    <AppBar position="sticky" sx={{
      background: `url(${require("../assets/img/fondo.png")})`,
    }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", [LIMITE]: "none" },
              alignItems: "center",
              p: 1,
            }}
          >
            <img
              sx={{
                display: "flex",
                mr: 1,
                ml: 1,
              }}
              src={logoChico}
              alt="logo emeiene"
            />
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.titulo} onClick={() => handleMenu(page.link)}>
                  <Typography textAlign="center"><NavLink to={page.link} style={getNavLinkStyle}>{page.titulo}</NavLink></Typography>
                </MenuItem>
              ))}
            </Menu>
            <Typography
              variant="h4"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: "flex",
                flexGrow: 1,
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: theme.palette.primary.main,
                textDecoration: "none",
                fontSize: 40,
              }}
            >
              emeiene
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", [LIMITE]: "flex" },
              p: 1,
              //justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              sx={{
                mr: theme.spacing(3),
                ml: 1,
              }}
              src={logoGrande}
              alt="logo emeiene"
            />
            {pages.map((page) => (
              <NavLink key={page.titulo} to={page.link} style={getNavLinkStyle}>{page.titulo}</NavLink>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
