import { useEffect, useState } from "react";

const DATA = {
  inicio: {
    doc: "/data/data_inicio.json",
    default: {
      titulos: ["EMEIENE"],
      texto: [],
      tarjetas: [],
    },
  },
  salud: {
    doc: "/data/data_salud.json",
    default: [],
  },
  videos: {
    doc: "/data/data_videos.json",
    default: [],
  },
  creaciones: {
    doc: "/data/data_creaciones.json",
    default: [],
  },
  juegos: {
    doc: "/data/data_juegos.json",
    default: [],
  },
};

export const getData = async ({ tabla }) => {
  const data_file = DATA[tabla] || {
    doc: `/data/data_${tabla}.json`,
    default: [],
  };
  if (!data_file) {
    return null;
  }
  try {
    const res = await fetch(data_file.doc);
    //console.log('getData(res)=',res);
    const text = await res.text();
    //console.log('getData(text)=',text);
    const data = JSON.parse(text);
    //console.log('getData(data)=',data);
    return data;
  } catch (e) {
    console.error(`getData(${tabla})=${data_file.doc}`);
    return data_file.default;
  }
};

export const useGetData = (tabla, postProcessData) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    console.log(`useGetData(${tabla}) request`);
    getData({ tabla })
      .then((dat) => {
        console.log(`useGetData(${tabla}) =`, dat);
        if (postProcessData) {
          try {
            const procDat = postProcessData(dat);
            console.log(`useGetData(${tabla}) postProcessData=`, procDat);
            setData(procDat);
          } catch (e) {
            console.error(`useGetData(${tabla}) postProcessData ERROR: `, e);
          }
        } else {
          setData(dat);
        }
      })
      .catch((e) => {
        console.error(`useGetData(${tabla}) ERROR: `, e);
      });
  }, [tabla, postProcessData]);

  return [data, setData];
};
