import React from "react";
import { Container, Grid, Paper } from "@mui/material";
import {
  SubTitulo,
  TextoGrande,
  TextoParrafo,
  TituloPagina,
} from "./textElements";
import { useGetData } from "./getdata";
import {
  useAnimElementos,
  ElementoAnimado,
  ElementoAnimadoSingle,
} from "./ElementoAnimado";

const ElementoCreacion = ({ cuadro }) => (
  <Grid container spacing={3} direction={{ xs: "column", sm: "row" }}>
    <Grid item xs={12}>
      <TextoGrande
        sx={{ mt: 4, mb: 2 }}
        texto={<b>{cuadro.titulo}</b>}
        align="left"
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Paper elevation={8}>
        <img src={cuadro.img} alt={cuadro.titulo} style={{ width: "100%" }} />
      </Paper>
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextoParrafo texto={cuadro.texto} />
    </Grid>
  </Grid>
);

const PaginaArte = () => {
  const [creaciones] = useGetData("creaciones");
  const [animPhase, callbackEndElementAnim] = useAnimElementos();

  return (
    <Container>
      <ElementoAnimadoSingle elementData={creaciones}>
        <TituloPagina texto={creaciones?.titulo || ""} />
        <SubTitulo texto={creaciones?.subtitulo} />
        <TextoParrafo texto={creaciones?.texto || []} />
      </ElementoAnimadoSingle>
      {creaciones &&
        creaciones.creaciones.map((cuadro, i) => (
          <ElementoAnimado
            key={i}
            iElement={i}
            elementData={cuadro}
            animPhase={animPhase}
            onEndAnim={callbackEndElementAnim}
          >
            <ElementoCreacion cuadro={cuadro} />
          </ElementoAnimado>
        ))}
    </Container>
  );
};

export default PaginaArte;
