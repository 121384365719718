import Slide from "@mui/material/Slide";
import React from "react";

export const ElementoAnimado = ({
  iElement,
  elementData,
  animPhase,
  children,
  onEndAnim,
}) => {
  return (
    <Slide
      direction="left"
      in={!!elementData && animPhase > iElement}
      style={{ transformOrigin: "0 0 0" }}
      timeout={200}
      onEntered={onEndAnim}
    >
      <div>{children}</div>
    </Slide>
  );
};

export const ElementoAnimadoSingle = ({ elementData, children }) => {
  return (
    <Slide in={elementData !== null} timeout={500} direction="right">
      <div>{children}</div>
    </Slide>
  );
};

export function useAnimElementos() {
  const [animPhase, setAnimPhase] = React.useState(1);
  const callbackEndElementAnim = () => {
    setAnimPhase(animPhase + 1);
  };
  return [animPhase, callbackEndElementAnim];
}
