import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./App.css";
import ResponsiveAppBar from "./components/responsiveAppBar";
import { CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";

const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#44bba5',
      light: '#7beed6',
      dark: '#008a76',
      contrastText: '#000000',
    },
    secondary: {
      main: '#814cc6',
      light: '#b47afa',
      dark: '#4f1f95',
      contrastText: '#ffffff',
    },
  },
  typography: {
    fontFamily: "Raleway",
  },
};

const customTheme = createTheme(themeOptions);

function App() {

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <div className="App">
        <ResponsiveAppBar />
        <Outlet />
      </div>
    </ThemeProvider>
  );
}

export default App;
