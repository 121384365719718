import { Container, Grid, Paper } from "@mui/material";
import { SubTitulo, TextoGrande, TextoParrafo, TituloPagina } from "./textElements";

const JUEGOS = [
  {
    titulo: "Triangular",
    texto: [
          "TRIANGULAR es  un juego lógico que invita a personas de todas las edades a disfrutar de una experiencia de observación y asociación que puede jugarse como Solitario o bien entre dos, tres y cuatro participantes.",
          "Conforman el juego un tablero con 24 fichas triangulares grabadas y pintadas a mano sobre madera reconstituida. Los grabados representan los 4  elementos naturales.",
    ],
    img: require("../assets/img/triangular.png"),
    link: "",
  },
  {
    titulo: "Memoria",
    texto: [
          "Es el tradicional juego de memoria, realizado en madera reconstituida, grabado con tecnología láser y pintado a mano.",
          "Cada juego consta con 20 pares de fichas hexagonales con diferentes temáticas: gatos, aves, deportes y un particular Kama Sutra.",
    ],
    img: require("../assets/img/memoria.png"),
    link: "",
  },
];

const PaginaJuegos = () => {
  return (
    <Container>
      <TituloPagina texto={"Servicios"} />
      <TextoParrafo 
        texto={[
          "Jugar es disfrutar y a la vez aprender, ambos aspectos fundamentales para una vida saludable.",
          "El JUEGO es una sanadora invitación a descubrir haciendo aquello que nos gusta. Ojala todas las personas, sin importar los años, siempre disfrutemos jugar.",
          "Tres fundamentos constituyen la base de los Juegos y Kits EMEIENE:",]}
      />
      <ul>
        <TextoParrafo texto={[
        <li>Que incentiven la observación, la memoria, la asociación, la diversión y la cooperación entre los participantes mas que la competencia.</li>,
        <li>Que personas de muy pocos a muchos años, puedan disfrutarlos.</li>,
        <li>Que se puedan jugar en solitario y también entre dos o mas participantes.</li>,
        ]}
        />
      </ul>
      <SubTitulo texto="Nuestros Servicios"/>
      { JUEGOS.map((juego,i) => (
        <Grid key={i} container spacing={3} direction={{ xs: "column", sm: "row" }}>
          <Grid item xs={12}><TextoGrande sx={{mt:4, mb:2}} texto={juego.titulo} align="left"/></Grid>
          <Grid item xs={12} sm={6}><Paper elevation={8}><img src={juego.img} alt={juego.titulo} style={{ width: "100%"}} /></Paper></Grid>
          <Grid item xs={12} sm={6}><TextoParrafo texto={juego.texto}/></Grid>
        </Grid>
      ))}
    </Container>
  );
};

export default PaginaJuegos;
