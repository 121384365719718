import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Paper,
  Chip,
  Typography,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  SubTitulo,
  TextoGrande,
  TextoParrafo,
  TituloPagina,
} from "./textElements";
import { useGetData } from "./getdata";
import {
  useAnimElementos,
  ElementoAnimado,
  ElementoAnimadoSingle,
} from "./ElementoAnimado";

const CardJuego = ({ juego }) => (
  <Card
    raised
    sx={{
      display: "flex",
      flexDirection: { xs: "column", md: "row" },
      mb: (theme) => theme.spacing(2),
    }}
  >
    <Box sx={{ mx: "auto" }}>
      <Paper elevation={5}>
        <CardMedia
          component="img"
          image={juego.img}
          alt={juego.titulo}
          sx={{ width: 500 }}
        />
      </Paper>
    </Box>
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(1),
      }}
    >
      <CardHeader
        title={
          <TextoGrande
            sx={{ mt: 4, mb: 2 }}
            texto={<b>{juego.titulo}</b>}
            align="left"
          />
        }
      />
      <CardContent>
        <TextoParrafo texto={juego.texto} />
        {juego.precio && (
          <Chip
            sx={{
              position: "absolute",
              mx: "auto",
              bottom: 10,
              right: 10,
              zIndex: 10,
              //my: (theme) => theme.spacing(1),
              boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
            }}
            color="secondary"
            label={<Typography variant="h6">{juego.precio}</Typography>}
            icon={<AttachMoneyIcon />}
          />
        )}
      </CardContent>
    </Box>
  </Card>
);

const PaginaJuegos = () => {
  const [juegos] = useGetData("juegos");
  const [animPhase, callbackEndElementAnim] = useAnimElementos();

  return (
    <Container>
      <ElementoAnimadoSingle elementData={juegos}>
        <TituloPagina texto={juegos?.titulo || "Juegos"} />
        <TextoParrafo texto={juegos?.texto || ""} />
        <ul>
          <TextoParrafo asListItem={true} texto={juegos?.texto_lista || []} />
        </ul>
        <SubTitulo texto={juegos?.subtitulo || "Nuestros Juegos"} />
      </ElementoAnimadoSingle>
      {juegos?.juegos &&
        juegos.juegos.map((juego, i) => (
          <ElementoAnimado
            key={i}
            iElement={i}
            elementData={juego}
            animPhase={animPhase}
            onEndAnim={callbackEndElementAnim}
          >
            <CardJuego juego={juego} />
          </ElementoAnimado>
        ))}
    </Container>
  );
};

export default PaginaJuegos;
