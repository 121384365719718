import React from "react";
import {
  Container,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Paper,
} from "@mui/material";
import {
  SubTitulo,
  TextoGrande,
  TextoParrafo,
  TituloPagina,
} from "./textElements";
import { VideoIncrustado } from "./VideoIncrustado";
import { useGetData } from "./getdata";
import {
  useAnimElementos,
  ElementoAnimado,
  ElementoAnimadoSingle,
} from "./ElementoAnimado";

const CardVideo = ({ video }) => (
  <Card
    raised
    sx={{
      display: "flex",
      minHeight: 408,
      flexDirection: { xs: "column", md: "row" },
      justifyContent: "space-between",
      alignItems: "center",
      mb: (theme) => theme.spacing(2),
    }}
  >
    <Box sx={{ mx: { xs: 0, md: "auto" }, flex: "0 0 560", width: 560 }}>
      <Paper elevation={5}>
        <CardMedia alt={video.titulo} sx={{ width: 560 }}>
          <VideoIncrustado videoid={video.videoid} />
        </CardMedia>
      </Paper>
    </Box>
    <Box
      sx={{
        width: 560,
        flex: "0 0 560",
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(1),
      }}
    >
      <CardHeader
        title={
          <TextoGrande
            sx={{ mt: 4, mb: 2 }}
            texto={<b>{video.titulo}</b>}
            align="left"
          />
        }
      />
      <CardContent>
        <TextoParrafo texto={video.texto} />
      </CardContent>
    </Box>
  </Card>
);

const PaginaVideos = () => {
  const [videos] = useGetData("videos");
  const [animPhase, callbackEndElementAnim] = useAnimElementos();

  return (
    <Container>
      <ElementoAnimadoSingle elementData={videos}>
        <TituloPagina texto={videos?.titulo || ""} />
        <TextoParrafo texto={videos?.texto || []} />
        <SubTitulo texto={videos?.subtitulo || "Videos"} />
      </ElementoAnimadoSingle>
      {!videos && <span>CARGANDO</span>}
      {videos?.videos &&
        videos.videos.map((video, i) => (
          <ElementoAnimado
            key={i}
            iElement={i}
            elementData={video}
            animPhase={animPhase}
            onEndAnim={callbackEndElementAnim}
          >
            <CardVideo video={video} />
          </ElementoAnimado>
        ))}
    </Container>
  );
};

export default PaginaVideos;
