import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Button,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TextoGrande } from "./textElements";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useGetData } from "./getdata";
import { useAnimElementos, ElementoAnimado } from "./ElementoAnimado";

const TarjetaSeccion = ({ titulo, imagen, altImagen, texto, link }) => {
  let navigate = useNavigate();
  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
  };

  return (
    <Card
      raised={true}
      sx={{
        maxWidth: 400,
        minHeight: 800,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <CardHeader
          title={titulo}
          sx={{
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
          }}
        />
        <CardMedia
          component="img"
          height="310"
          image={imagen}
          alt={altImagen}
        />
        <CardContent sx={{}}>
          <TextoGrande texto={texto} sx={{ fontSize: 20 }} />
        </CardContent>
      </Box>
      <CardActions>
        <Button onClick={(event) => handleClick(event, link)}>Ver más</Button>
      </CardActions>
    </Card>
  );
};

const getTarjeta = (tarjeta) => <TarjetaSeccion {...tarjeta} />;

const PaginaInicio = () => {
  const [contenido] = useGetData("inicio");
  const [animPhase, callbackEndElementAnim] = useAnimElementos();

  return (
    <Container>
      <Slide in={contenido !== null} timeout={500} direction="right">
        <div>
          {contenido &&
            contenido.titulos &&
            contenido.titulos.map((titulo, i) => (
              <TextoGrande key={i} texto={<strong>{titulo}</strong>} />
            ))}
          <TextoGrande texto={contenido?.texto || []} align={"justify"} />
        </div>
      </Slide>
      <Container>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="flex-start"
          direction={{ xs: "column", sm: "row" }}
        >
          {contenido?.tarjetas &&
            contenido.tarjetas.map((tarjeta, i) => (
              <Grid key={i} item xs={12} sm={6} md={4}>
                <ElementoAnimado
                  iElement={i}
                  animPhase={animPhase}
                  onEndAnim={callbackEndElementAnim}
                  elementData={tarjeta}
                  getChildren={getTarjeta}
                >
                  <TarjetaSeccion {...tarjeta} />
                </ElementoAnimado>
              </Grid>
            ))}
        </Grid>
      </Container>
    </Container>
  );
};

export default PaginaInicio;
